import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { COLORS } from 'COLORS'
import { TEXT_STYLES } from 'TEXT_STYLES'

const Banner = styled.div`
  width: 100%;
  height: 65px;
  box-shadow: 0 3px 3px 1px rgba(207, 216, 220, 0.5);
  display: flex;
  align-items: center;
  padding: 0 30px;
  background-color: ${COLORS.grey700};
  ${TEXT_STYLES.BodyWhite}
`

const MessageBanner = props => {
  return <Banner className={props.className}>{props.children}</Banner>
}

MessageBanner.propTypes = {
  children: PropTypes.any.isRequired
}

export { MessageBanner }
